export const hormonalChangesEnum = [
    'Ovarios poliquísticos',
    'Menstruacion irregular',
    'Cambio de sexo',
    'Anticonceptivos: Diu o Implante',
]

export const PauseDeleteProject = {
    INACTIVE: "INACTIVE",
    ACTIVE: "ACTIVE",
    FINISHED: "FINISHED",
    CANCELED: "CANCELED"
}

export const mesesDelAño = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
];


export const electroClearValues = {
    idClient: 0,
    hormonalAlterations: 47,
    type: "ELECTRO",
    fase: "ESTUDIO",
    zones: [],
    active: true,
    afterCareSigned: "",
    informedConsentSigned: "",
    deactivationReason: -1,
    observations: "",
    firstDate: false,
    boostMode: false,
    drug: 54,
    alergies: 56,
    skinType: 66,
    ageRange: 60,
    gender: 63,
    creationDate: new Date().toJSON().split("T")[0],   // TODO Aqui la fecha buena
    description: "",
    irritation: false,
    name: "",
    photoSensitiveMedicines: "no",
    status: PauseDeleteProject.ACTIVE,
    monitoring: false,
    comments: "" // TODO revisar si se queda definitivo en este scope
}

export const genderValues = {
    MUJER: "Mujer",
    HOMBRE: "Hombre",
    TRANS_FEM: "Trans Fem.",
    TRANS_MASC: "Trans Masc."
}
export const genderIds = {
    MUJER: 63,
    HOMBRE: 64,
    TRANS_FEM: 65,
    TRANS_MASC: 400
}
export const allergiesList = new Map([["Látex", "Guantes de nitrilo"], ["Metales", "Agujas de oro"], ["Blemish", "Blemish"],])

// export const facialKeys = ["P", "A", "W", "L", "O", "F", "M", "X", "Y", "C", "Z", "G", "H", "E", "N", "R", "S", "B", "T", "V", "J"] con Oreja
// export const bodyMascKeys = ["PC", "LA2", "LA1", "AB2", "AB1", "PB", "IN", "PE", "PI3", "PI2", "PI1", "RO", "MU3", "MU2", "MU1", "MA", "BI2", "BI1", "BS2", "BS1", "HO", "ES2", "ES1", "LU", "GL2", "GL1", "AX", "NU2", "NU1", "PA"] con nuca en dos zonas
// export const bodyFemKeys = ["LA2-M", "LA1-M", "MA-M", "BI2-M", "BI1-M", "BS2-M", "BS1-M", "PE-M", "PI3-M", "PI2-M", "PI1-M", "RO-M", "MU3-M", "MU2-M", "MU1-M", "EC-M", "AB2-M", "AB1-M", "PB-M", "IN-M", "HO-M", "LU-M", "GL2-M", "GL1-M", "AX-M", "NU2-M", "NU1-M", "PA-M", "ES2-M", "ES1-M", "AR-M", "SE-M", "IB-M"] con nuca en dos zonas
// export const bodyMascKeys: string[] = ["PC", "LA2", "LA1", "AB2", "AB1", "PB", "IN", "PE", "PI3", "PI2", "PI1", "RO", "MU3", "MU2", "MU1", "MA", "BI2", "BI1", "BS2", "BS1", "HO", "ES2", "ES1", "LU", "GL2", "GL1", "AX", "NU", "PA"]
export const bodyMascKeys: string[] = []
// export const bodyFemKeys: string[] = ["LA2-M", "LA1-M", "MA-M", "BI2-M", "BI1-M", "BS2-M", "BS1-M", "PE-M", "PI3-M", "PI2-M", "PI1-M", "RO-M", "MU3-M", "MU2-M", "MU1-M", "EC-M", "AB2-M", "AB1-M", "PB-M", "IN-M", "HO-M", "LU-M", "GL2-M", "GL1-M", "AX-M", "NU-M", "PA-M", "ES2-M", "ES1-M", "AR-M", "SE-M", "IB-M"]
export const bodyFemKeys: string[] = []
// export const facialKeys: string[] = ["P", "A", "W", "L", "F", "M", "X", "Y", "C", "Z", "G", "H", "E", "N", "R", "S", "B", "T", "V", "J", "O", "K"]
export const facialKeys: string[] = []
// export const zonesKeysOrderer = ["S", "B", "E", "J", "T", "X", "Y", "M", "Z", "F", "L", "V", "O", "N", "R", "P", "K", "A", "C", "H", "G", "W"]
export const zonesKeysOrderer: string[] = []

export const APP_VERSION = `v 1.22.1`